import { isFunction } from './isFunction';
import { isObject } from './isObject';

/**
 * Clone an array or object
 * @param source - the object to clone
 * @param deep - whether to return a deep clone (true) or shallow (default)
 */
export function clone<T>(source: T, deep: boolean | undefined = false): T {
  // If it's not an object -- meaning not Array or Object
  // then it's a primitive or function. Return it.
  if (!isObject(source) && !Array.isArray(source)) {
    return source;
  }

  // If an object knows how to clone itself, trust that it will
  // do a better job than we would!
  if ((source as any).clone && isFunction((source as any).clone)) {
    return (source as any).clone();
  }
  const destination = Array.isArray(source) ? [] : {};
  Object.keys(source).forEach((prop) => {
    if (deep) {
      if (Array.isArray((source as any)[prop])) {
        (destination as any)[prop] = cloneArray((source as any)[prop], deep);
      } else if (isObject((source as any)[prop])) {
        (destination as any)[prop] = clone((source as any)[prop], deep);
      } else {
        (destination as any)[prop] = (source as any)[prop];
      }
    } else {
      (destination as any)[prop] = (source as any)[prop];
    }
  });
  return destination as T;
}

export function cloneArray<T>(array: T[], deep = false): T[] {
  if (!Array.isArray(array)) throw new Error('Not an array.');
  if (!deep) return [...array];
  // Recurse down in:
  return [...array].map((item: T) => {
    if (Array.isArray(item)) return cloneArray(item, deep) as unknown as T;
    if (isObject(item)) return clone(item, deep) as unknown as T;
    return item;
  });
}

export function cloneDeep<T>(source: T): T {
  return clone(source, true);
}
